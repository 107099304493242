import { ReactNode } from "react";
import styles from "./Table.module.css";

export const Table = ({ children }: { children: ReactNode }) => {
    return (
        <div className={styles.table}>
            {children}
        </div>
    )
}

export const TableRow = ({ label, value }: { label: string, value: string | number | (() => JSX.Element) | undefined}) => {
    return (
        <div className={styles.row}>
            <div>{label}</div>
            <div>{
                typeof (value) === "string" || typeof (value) === "number"
                    ? value
                    : typeof (value) === "undefined"
                        ? ''
                        : value()
            }</div>
        </div>
    )
}