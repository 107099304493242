import React from "react";
import { api, useGetData } from "../components";
import { StarlinkDataModel } from "./Models";

/**
 * Shape of the data that is returned by the remote API call
 */
interface IApiModel {
    meta: {
        installationId: number;
        hasStarlink: boolean;
    },
    performance: {
        timeStampsMillis: number[];
        maxPopPingLatency: number[];
        averagePopPingLatency: number[];
        maxDownlinkRateMbps: number[];
        maxUplinkRateMbps: number[];
        maxPopPingDropRate: number[];
        averagePopPingDropRate: number[];
        stateName: string[];
        statePercentage: number[];
    };
    dataVolumes: {
        timeStampsMillis: number[];
        dataVolumes: number[];
    };
    status: {
        alerts: {
            motorsStuck: boolean;
            thermalThrottle: boolean;
            thermalShutdown: boolean;
            mastNotNearVertical: boolean;
            unexpectedLocation: boolean;
            slowEthernetSpeeds: boolean;
            slowEthernetSpeeds100: boolean;
            roaming: boolean;
            installPending: boolean;
            isHeating: boolean;
            powerSupplyThermalThrottle: boolean;
            isPowerSaveIdle: boolean;
            movingWhileNotMobile: boolean;
            movingTooFastForPolicy: boolean;
            dbfTelemStale: boolean;
            lowMotorCurrent: boolean;
            lowerSignalThanPredicted: boolean;
            obstructionMapReset: boolean;
        };
        info: {
            timeMillis: number;
            hardwareVersion: string;
            softwareVersion: string;
            state: string;
            uptime: number;
            secFirstNonEmptySlot: number;
            popPingDropRate: number;
            downlink: number;
            uplink: number;
            popPingLatency: number;
            alerts: number;
            fractionObstructed: number;
            currentlyObstructed: boolean;
            obstructionDuration: number;
            obstructionInterval: number;
            azimuth: number;
            elevation: number;
            snrAboveNoise: boolean;
            obstructionImageData: string;
        };
    }
}

/**
 * Maps data returned from the remote API to a UI model
 * @param apiData
 * @returns
 */
function mapper(apiData: IApiModel): StarlinkDataModel {
    //console.log("apiData", apiData);
    return {
        shipId: apiData.meta.installationId,
        hasStarlink: apiData.meta.hasStarlink,
        dataVolumes: {
            timeStampMillis: [...apiData.dataVolumes.timeStampsMillis],
            values: [...apiData.dataVolumes.dataVolumes]
        },
        performance: { ...apiData.performance },
        status: {
            alerts: { ...apiData.status.alerts },
            info: { ...apiData.status.info},
        }
    }
}

/**
 * Hook to retrieve Starlink Data for a given ship over a given time range
 * @param installationId Id of the Installation whose data is to be retrieved
 * @param fromMillis Lower date range limit in Unix Epoch seconds.
 * @param toMillis Upper date range limit in Unix Epoch seconds.
 * @returns
 */
const useFetchStarlinkData = (installationId: number | undefined, fromMillis: number, toMillis: number) => {

    const [url, setUrl] = React.useState<string>('');
    const state = useGetData(url, mapper);

    React.useEffect(() => {

        if (typeof installationId === "undefined") return;

        const url = api.urls.starlink.get.data(installationId, fromMillis, toMillis);

        setUrl(url);

    }, [installationId, fromMillis, toMillis]);

    return { state };
}

export {useFetchStarlinkData }
