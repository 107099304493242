import React, { useContext, useEffect, useState } from "react";

import { IDataVolumesChartState } from "./dataVolumes/IDataVolumesChartState";
import { IDownloadSpeedsChartState } from "./downloadSpeed/IDownloadSpeedsChartState";
import { IRfPerformanceChartState } from "./rfPerformance/IRfPerformanceChartState";
import { IUploadSpeedsChartState } from "./uploadSpeed/IUploadSpeedsChartState";

import { useFetchDataVolumes } from "../../../../../../../../dataServices/metrics/dataVolumes";
import { useFetchDownloadSpeeds } from "../../../../../../../../dataServices/metrics/downloadSpeeds";
import { useFetchRfPerformance } from "../../../../../../../../dataServices/metrics/rfPerformance";
import { useFetchUploadSpeeds } from "../../../../../../../../dataServices/metrics/uploadSpeeds";
import { useFetchWanStatuses } from "../../../../../../../../dataServices/metrics/wanStatuses";

import { MapDateRangeContext } from "../../../../../../../../contexts/components/MapDateRangeContext";
import { SelectedShipContext } from "../../../../../../../../contexts/components/SelectedShipContext";
import { Charts } from "./Charts";
import { mapDataVolumesData, mapDownloadSpeedsData, mapRfPerformanceData, mapUploadSpeedsData, mapWanStatusesData } from "./dataMappers";
import { IWanStatusesChartState } from "./wanStatuses/IWanStatusesChartState";

const defaultData = {
    fromMillis: 0,
    toMillis: 0,
    intervalMillis: 0,
    series: []
}

const stateIsLoading = {
    isLoading: true, isError: false, data: defaultData
}

const stateIsError = {
    isLoading: false, isError: true, data: defaultData
}

const stateIsIdle = {
    isLoading: false, isError: false, data: defaultData
}

export const ChartsDataContainer : React.FC = () => {

    const {ship} = useContext(SelectedShipContext);
    const {fromMillis, toMillis, intervalMillis} = useContext(MapDateRangeContext);

    const [wanStatusesChartState, setWanStatusesChartState] = useState<IWanStatusesChartState>(stateIsIdle);
    const [dataVolumesChartState, setDataVolumesChartState] = useState<IDataVolumesChartState>(stateIsIdle);
    const [downloadSpeedsChartState, setDownloadSpeedsChartState] = useState<IDownloadSpeedsChartState>(stateIsIdle);
    const [rfPerformanceChartState, setRfPerformanceChartState] = useState<IRfPerformanceChartState>(stateIsIdle);
    const [uploadSpeedsChartState, setUploadSpeedsChartState] = useState<IUploadSpeedsChartState>(stateIsIdle);

    const { state: fetchWanStatusesState } = useFetchWanStatuses(ship?.installationId, fromMillis, toMillis, intervalMillis);
    const { state: fetchDataVolumesState } = useFetchDataVolumes(ship?.installationId, fromMillis, toMillis, intervalMillis);
    const { state: fetchDownloadSpeedsState } = useFetchDownloadSpeeds(ship?.installationId, fromMillis, toMillis, intervalMillis);
    const { state: fetchRfPerformanceState } = useFetchRfPerformance(ship?.installationId, fromMillis, toMillis, intervalMillis);
    const { state: fetchUploadSpeedsState } = useFetchUploadSpeeds(ship?.installationId, fromMillis, toMillis, intervalMillis);

    // watch for changes to Wan Statuses data
    useEffect(() => {
        switch (fetchWanStatusesState.status){
            case "in_progress":
                setWanStatusesChartState(stateIsLoading);
                break;
            case "completed":
                setWanStatusesChartState({isLoading: false, isError: false, data: fetchWanStatusesState.data ? mapWanStatusesData(fetchWanStatusesState.data) : defaultData});
                break;
            case "error":
                setWanStatusesChartState(stateIsError);
                break;
            default:
                setWanStatusesChartState(stateIsIdle);
        }
    }, [fetchWanStatusesState.status, fetchWanStatusesState.data]);

    // watch for changes of Data Volumes data
    useEffect(() => {
        switch (fetchDataVolumesState.status){
            case "in_progress":
                setDataVolumesChartState(stateIsLoading);
                break;
            case "completed":
                setDataVolumesChartState({isLoading: false, isError: false, data: fetchDataVolumesState.data ? mapDataVolumesData(fetchDataVolumesState.data) : defaultData});
                break;
            case "error":
                setDataVolumesChartState(stateIsError);
                break;
            default:
                setDataVolumesChartState(stateIsIdle);
            }
    }, [fetchDataVolumesState.status, fetchDataVolumesState.data]);

    // watch for changes of Download Speeds data
    useEffect(() => {
        switch (fetchDownloadSpeedsState.status){
            case "in_progress":
                setDownloadSpeedsChartState(stateIsLoading);
                break;
            case "completed":
                setDownloadSpeedsChartState({isLoading: false, isError: false, data: fetchDownloadSpeedsState.data ? mapDownloadSpeedsData(fetchDownloadSpeedsState.data) : defaultData});
                break;
            case "error":
                setDownloadSpeedsChartState(stateIsError);
                break;
            default:
                setDownloadSpeedsChartState(stateIsIdle);
            }
    }, [fetchDownloadSpeedsState.status, fetchDownloadSpeedsState.data]);

    // watch for changes to RF Performance data
    useEffect(() => {
        switch (fetchRfPerformanceState.status){
            case "in_progress":
                setRfPerformanceChartState(stateIsLoading);
                break;
            case "completed":
                setRfPerformanceChartState({isLoading: false, isError: false, data: fetchRfPerformanceState.data ? mapRfPerformanceData(fetchRfPerformanceState.data) : defaultData});
                break;
            case "error":
                setRfPerformanceChartState(stateIsError);
                break;
            default:
                setRfPerformanceChartState(stateIsIdle);
            }
    }, [fetchRfPerformanceState.status, fetchRfPerformanceState.data]);

    // watch for changes to Upload Speeds data
    useEffect(() => {
        switch (fetchUploadSpeedsState.status){
            case "in_progress":
                setUploadSpeedsChartState(stateIsLoading);
                break;
            case "completed":
                setUploadSpeedsChartState({isLoading: false, isError: false, data: fetchUploadSpeedsState.data ? mapUploadSpeedsData(fetchUploadSpeedsState.data) : defaultData});
                break;
            case "error":
                setUploadSpeedsChartState(stateIsError);
                break;
            default:
                setUploadSpeedsChartState(stateIsIdle);
        }
    }, [fetchUploadSpeedsState.status, fetchUploadSpeedsState.data]);

    return (
        <Charts 
            showWanStatuses={ship?.usesMarpoint}
            dataVolumesChartState={dataVolumesChartState}
            downloadSpeedsChartState={downloadSpeedsChartState}
            uploadSpeedsChartState={uploadSpeedsChartState}
            rfPerformanceChartState={rfPerformanceChartState}
            wanStatusesChartState={wanStatusesChartState}
            />  
    )
}
