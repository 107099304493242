import { ReactNode } from "react";
import styles from "./Grid.module.css";

export const Grid = ({ children }: {children : ReactNode}) => {
    return (
        <div className={styles.grid}>
            {children}
        </div>
    );
}

export const GridCol = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className={styles.col}>
            {children}
        </div>
    )
}
