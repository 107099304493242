import {useEffect, useState} from "react";
import { api, useGetData } from "../../components";
import { IClientNotificationEmails } from "./Models";

interface IApiEmail {
    id: number;
    email: string;
}

interface IApiClient {
    clientId: number;
    clientName: string;
    emailAddresses: IApiEmail[];
}

// function maps data retrieved from the remote api to 
// models that can be used in the UI
function mapper(apiModel: IApiClient[]) : IClientNotificationEmails[]{
    
    if (typeof apiModel === "undefined" || apiModel.length === 0) return [];

    // no real need to map anything
    return apiModel;
}

// Hook to retrieve the Notification Emails for the currently signed in user
export const useFetchClientNotificationEmails = () => {

    const [url, setUrl] = useState<string>('');
    const state = useGetData(url, mapper);

    useEffect(() => {

        const url = api.urls.packages.get.notificationEmails();

        setUrl(url);

    },[setUrl]);

    return { state };
}