import styles from "./PanelContent.module.css";

interface PanelContentProps {
    children?: React.ReactNode;
}

export const PanelContent = ({children}: PanelContentProps) => {
    return (
        <div className={styles.root}>
            {children}
        </div>
    )
}