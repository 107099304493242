/**
 * React context that provides Satellite Beam Contour
 * data for the currently logged in User.
 */
import React from "react";
import { useFetchBeamContours, BeamContourModel } from "../../dataServices/beamContours";
import { BeamContoursContext } from "./BeamContoursContext";


/**
 * Creates a context for the Satellite Beam Contours available to the
 * currently logged in User.
 */
function BeamContoursProvider({children} : {children : React.ReactNode}){

    const [beamContours, setBeamContours] = React.useState<BeamContourModel[]>([]);

    const state = useFetchBeamContours();

    React.useEffect(() => {
        switch (state.status){
            case "completed":
                if (state.data){
                    setBeamContours(state.data);
                }
                break;
            case "in_progress":
            case "error":
            case "idle":
                setBeamContours([]);
                break;
        }
    }, [state.status, state.data])

    const value = {beamContours, isLoading: state.status === "in_progress"}

    return (
        <BeamContoursContext.Provider value={value}>
            {children}
        </BeamContoursContext.Provider>
    )
}

/**
 * Returns the Satellite Beam Contours for the currently logged in user.
 */

export {BeamContoursProvider}