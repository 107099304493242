import { RefObject, createRef, useEffect } from "react";
import { select as d3select } from "d3-selection";
import { axisLeft as d3axisLeft, AxisScale as d3AxisScale } from "d3-axis";

interface LeftAxisProps {
    scale: d3AxisScale<number>;
    tickValues: number[];
    chartWidth: number;
    chartHeight: number;
    marginLeft: number;
    labelText: string;
}

export const LeftAxis = ({
    scale,
    tickValues,
    chartWidth,
    chartHeight,
    marginLeft,
    labelText,
}: LeftAxisProps) => {

    const elRef: RefObject<SVGGElement> = createRef();

    useEffect(() => {
        if (elRef.current) {
            // this seems a bit heavy handed, but it works and I can't
            // find a way at the moment to only update various components
            // of the axis without them being duplicated

            // So.. remove all the components of the axis
            d3select(elRef.current).selectAll("path,line,text,g").remove();

            // before recreating the axis
            const axis = d3axisLeft(scale);

            axis.tickValues(tickValues);

            d3select(elRef.current)
                // draw the axis
                .call(axis)
                // shorten the default tick mark lines
                .call((g =>
                    g.selectAll(".tick line")
                        .attr("x2", 0)
                ))
                // add lines that cross the chart
                .call((g =>
                    g.selectAll(".tick line")
                        .clone()
                        .attr("stroke-opacity", 0.2)
                        .attr("x2", chartWidth)
                ))
                // remove the axis base line
                .call((g =>
                    g.select(".domain")
                        .remove()
                ))
                // append a vertically oriented label
                .call((g => 
                    g.append("text")
                        .attr("transform", "rotate(-90)")
                        .attr("y", -marginLeft + 15)
                        .attr("x", -(chartHeight / 2))
                        .attr("fill", "currentColor")
                        .attr("text-anchor", "middle")
                    .text(labelText)
                ))
                ;
        }
    }, [elRef, scale, tickValues, chartWidth, labelText, marginLeft, chartHeight])

    return (
        <g ref={elRef} transform={`translate(${marginLeft}, 0)`}></g>
    )
}


