import React from "react";

export type UserDetailsType = {
    userName: string | null;
    canSchedulePackageChanges: boolean;
    canCancelPackageChanges: boolean;
    canViewSatelliteBeamOverlays: boolean;
    canViewPackagePrices: boolean;
    canViewInvoiceHistory: boolean;
    canPerformVnoAdmin: boolean;
    canViewStarlink: boolean;
}

type AuthContextType = {
    user: UserDetailsType | null;
    signIn: (email: string, password: string, onSuccess: () => void, onError: (message: string) => void) => void;
    signOut: (onSuccess: () => void) => void;
}

export const AuthContext = React.createContext<AuthContextType>(null!);
