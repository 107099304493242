import classNames from "classnames";
import { max as d3max } from "d3-array";
import {
    scaleLinear as d3scaleLinear,
    scaleUtc as d3scaleUtc
} from "d3-scale";
import { Fragment, useEffect, useRef, useState } from "react";
import { froundToIntlString, froundToNumber } from "../../../../../../../utils/rounding";
import {
    Area,
    BottomAxis,
    ChartContainer, ChartMargins, LeftAxis, RightAxis, VerticalBars,
} from "../chartComponents";
import { Panel } from "../panelComponents";
import styles from "./DataVolumes.module.css";
import { generateTicksForYAxis } from "../chartComponents/Utils";

export interface DataVolumesDataModel {
    chartData: ChartData[],
}

interface ChartData {
    timeStampMillis: number;
    value: number;
}


export const DataVolumes = ({ data }: { data: DataVolumesDataModel }) => {

    const containerRef: React.RefObject<HTMLDivElement> = useRef(null); 
    const [chartWidth, setChartWidth] = useState(0);

    useEffect(() => {
        function handleResize() {
            if (containerRef.current) {
                setChartWidth(containerRef.current.clientWidth - 2);
            }
        }

        // watch for window resize events
        window.addEventListener("resize", handleResize);

        // get initial size
        handleResize();

        // remove event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [containerRef]);

    return (
        <div ref={containerRef}>
            <Container
                chartWidth={chartWidth}
                data={data}
            />
        </div>
    )
}


const Container = ({
    chartWidth,
    data,
}: {
        chartWidth: number,
        data: DataVolumesDataModel,
}) => {

    const chartHeight = 150;

    return (
        <Panel title="Traffic Data" uom="(GB)">
            <ChartContainer overallHeight={chartHeight}>
                <Chart
                    chartWidth={chartWidth}
                    chartHeight={chartHeight}
                    data={data.chartData}
                />
            </ChartContainer>
            <>
                <ChartKeys />
                <DataVolumesTotal chartData={data.chartData} />
            </>
        </Panel>
    )
}

const BINARY_DIVISOR = 1073741824;

const chartMargins: ChartMargins = {
    marginLeft: 50,
    marginRight: 80,
    xAxisHeight: 20,
}

function createRunningTotalValues(data: ChartData[]) {
    let sum = 0;
    return data.map((d) => ({
        timeStampMillis: d.timeStampMillis,
        value: (sum += d.value)
    }));
}

const Chart = ({
    chartWidth,
    chartHeight,
    data }: {
        chartWidth: number,
        chartHeight: number,
        data: ChartData[]
    }) => {

    const valueDivisor = BINARY_DIVISOR;

    if (data.length === 0) return null;

    const adjustedData: ChartData[] = data.map((d) => ({
        timeStampMillis: d.timeStampMillis,
        value: froundToNumber(d.value / valueDivisor, 2)
    }));

    //console.log("adjusted data", adjustedData);

    const minTimeStamp = adjustedData[0].timeStampMillis;
    const maxTimeStamp = adjustedData[adjustedData.length - 1].timeStampMillis;
    const maxValue = d3max(adjustedData, d => d.value) ?? 0;
    const maxValueAdjusted = maxValue > 0 ? maxValue : 1;
    const valueSum = adjustedData.reduce((a, c) => a + c.value, 0);
    const valueSumAdjusted = valueSum > 0 ? valueSum : 1;

    const contentWidth = chartWidth - chartMargins.marginLeft - chartMargins.marginRight;
    const contentHeight = chartHeight - chartMargins.xAxisHeight;

    // a time based scale for the x axis
    const xScale = d3scaleUtc()
        .domain([minTimeStamp, maxTimeStamp])
        .range([chartMargins.marginLeft + 20, chartWidth - chartMargins.marginRight - 20]);

    // a number scale for the left (individual volume) y axis
    const valueYScale = d3scaleLinear()
        .domain([0, maxValueAdjusted])
        .range([contentHeight, 10])
        .nice();

    // a number scale for the right (total volume) y axis
    const sumYScale = d3scaleLinear()
        .domain([0, valueSumAdjusted])
        .range([contentHeight, 10])
        .nice();

    // generate ticks for the y scales
    const valueTicks = generateTicksForYAxis(valueYScale.domain());
    const sumTicks = generateTicksForYAxis(sumYScale.domain());

    return (
        <Fragment>
            <LeftAxis
                tickValues={valueTicks}
                scale={valueYScale}
                chartWidth={contentWidth}
                chartHeight={contentHeight}
                marginLeft={chartMargins.marginLeft}
                labelText="Daily Traffic (GB)"
            />
            <BottomAxis
                width={contentWidth}
                y={chartHeight - chartMargins.xAxisHeight}
                scale={xScale}
            />
            <RightAxis
                tickValues={sumTicks}
                scale={sumYScale}
                chartWidth={chartWidth}
                chartHeight={contentHeight}
                marginRight={chartMargins.marginRight}
                labelText="Total Traffic (GB)"
            />
            <Area
                color='#50BDFE'
                data={createRunningTotalValues(adjustedData)}
                xScale={xScale}
                yScale={sumYScale}
            />
            <VerticalBars
                color='#544FC5'
                data={adjustedData}
                barWidth={20}
                height={contentHeight}
                xScale={xScale}
                yScale={valueYScale}
            />
        </Fragment>
    )
}

const ChartKeys = () => {
    return (
        <div className={styles.keysContainer}>
            <div>
                <span className={classNames(styles.keyIndicator, styles.total)}></span>
                <span>Total Traffic</span>
                <span className={classNames(styles.keyIndicator, styles.item)}></span>
                <span>Daily Traffic</span>
            </div>
        </div>
    )
}

const DataVolumesTotal = ({ chartData }: { chartData: ChartData[] }) => {

    const initialValue = 0;
    const total = chartData.reduce((accumulator, currentValue) => accumulator + froundToNumber((currentValue.value / BINARY_DIVISOR), 2), initialValue);
    return (
        <div className={styles.totalsContainer}>
            <div>
                Total: {froundToIntlString(total, 2) }GB
            </div>
        </div>
    )
}