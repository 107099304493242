import { froundToIntlString, froundToNumber } from "../../../../../../../utils/rounding";
import styles from "./Summary.module.css";
import classNames from "classnames";

export interface SummaryProps {
    maxDownload: number;
    maxUpload: number;
    maxLatency: number;
    avgLatency: number;
    maxDropRate: number;
    avgDropRate: number;
    connectedState: ConnectedState[]
}

interface ConnectedState {
    stateName: string;
    statePercentage: number;
}

function createConicGradient(data: ConnectedState[]) {
    // copy the input data
    const ranked = data.slice();

    // sort the data by ascending percentage
    ranked.sort((a, b) => {
        if (a.statePercentage < b.statePercentage) {
            return -1;
        } else if (a.statePercentage > b.statePercentage) {
            return 1;
        }
        return 0;
    })

    let content = '';
    let lastPercentage = 0;
    ranked.forEach((value) => {
        // last entry in the list will be marked as 100%
        const percentage = lastPercentage + value.statePercentage;
   
        const colour = value.stateName === "CONNECTED" ? "green" : "red";

        // this value runs from the last percentage to 
        content += `${colour} ${lastPercentage}% ${percentage}%,`;
        lastPercentage = percentage;
    });

    // remove the trailing comma
    content = content.replace(/,$/, '');

    return `conic-gradient(${content})`;
}

export const Summary = ({ data }: { data: SummaryProps }) => {

    const maxDownload = froundToNumber(data.maxDownload, 2);
    const maxUpload = froundToNumber(data.maxUpload, 2);
    const maxLatency = froundToNumber(data.maxLatency, 0);
    const avgLatency = froundToNumber(data.avgLatency, 0);
    const maxDropRate = froundToNumber(data.maxDropRate, 3);
    const avgDropRate = froundToNumber(data.avgDropRate, 3);

    const gradient = createConicGradient(data.connectedState);
    //console.log(gradient);

    return (
        <div className={styles.root}>
            <div className={classNames(styles.item, styles.download)}>
                <div className={classNames(styles.title, styles.downloadTitle) }>Download</div>
                <div className={styles.value}>Max: {maxDownload } Mbps</div>
            </div>
            <div className={classNames(styles.item, styles.upload) }>
                <div className={classNames(styles.title, styles.uploadTitle)}>Upload</div>
                <div className={styles.value}>Max: {maxUpload } Mbps</div>
            </div>
            <div className={classNames(styles.item, styles.latency) }>
                <div className={classNames(styles.title, styles.latencyTitle)}>Latency</div>
                <div className={styles.value}>{avgLatency } ms</div>
                <div className={styles.value}>Max: {maxLatency} ms</div>
            </div>
            <div className={classNames(styles.item, styles.pingDropRate)}>
                <div className={classNames(styles.title, styles.pingDropRateTitle)}>Ping Drop Rate</div>
                <div className={styles.value}>{avgDropRate}</div>
                <div className={styles.value}>Max: {maxDropRate}</div>
            </div>
            <div className={styles.connectedState}>
                <div style={{ width: '60px', height: '60px', borderRadius: '50%', backgroundImage: gradient }}></div>
                <div className={styles.connectedLabels}>
                    {data.connectedState.map((d, i) => (
                        <div key={i} className={styles.connectedLabel}>
                            <div className={styles.connectedLabelStateName}>{d.stateName}</div>
                            <div>{froundToIntlString(d.statePercentage, 1)}%</div>
                        </div>
                    )) }
                </div>
            </div>
        </div>
    );
}