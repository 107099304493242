import styles from "./ChartKeys.module.css";
import classNames from "classnames";

export const ChartKeys = () => {
    return (
        <div className={styles.root}>
            <div>
                <span className={classNames(styles.indicator, styles.download)}></span>
                <span>Download</span>
                <span className={classNames(styles.indicator, styles.upload)}></span>
                <span>Upload</span>
                <span className={classNames(styles.indicator, styles.latency)}></span>
                <span>Latency</span>
            </div>
        </div>
    )
}