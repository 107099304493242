﻿import styles from "./Panel.module.css";
import { PanelContent } from "./PanelContent";
import { PanelTitle } from "./PanelTitle";

interface PanelProps {
    title: string;
    uom?: string;
    children: React.ReactNode;
}

export const Panel = ({ title, uom, children }: PanelProps) => {
    return (
        <div className={styles.root}>
            <PanelTitle title={title} uom={uom} />
            <PanelContent>
                {children}
            </PanelContent>
        </div>
    );
};
