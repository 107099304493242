/**
 * React Context that provides Location and Route data
 * for the currently selected Ship and Map Date Range.
 */
import React, { useContext } from "react";
import { useFetchShipLocations } from "../../dataServices/metrics/shipLocation";
import { MapLocationType, MapLocationsContext } from "./MapLocationsContext";
import { SelectedShipContext } from "./SelectedShipContext";
import { MapDateRangeContext } from "./MapDateRangeContext";

/**
 * Creates a context for the geographical locations and routes for 
 * the currently selected ship over the currently selected time span
 * as determined by the nearest ISelectedShipContext and IMapDateRangeContext
 */
function MapLocationsProvider({children} : {children: React.ReactNode}){

    const [locations, setLocations] = React.useState<Map<number, MapLocationType>>(new Map<number, MapLocationType>());
    const [routes, setRoutes] = React.useState<Array<Array<Array<number>>>>(new Array<Array<Array<number>>>());

    const {ship} = useContext(SelectedShipContext);
    const {fromMillis, toMillis, intervalMillis} = useContext(MapDateRangeContext);

    const state = useFetchShipLocations(ship?.installationId, fromMillis, toMillis, intervalMillis);

    React.useEffect(() => {
            switch (state.status){
                case "in_progress":
                    setLocations(new Map<number, MapLocationType>());
                    break;
                case "completed":
                    if (state.data){
                        const x  : Array<Array<Array<number>>> = state.data.routes.map((route) => {
                            return route.locations.map((location) => {
                                return [location.lng??0, location.lat??0]
                            })
                        });
                        setRoutes(x);
                        // construct the locations by mapping the raw ship locations
                        // data to a Map indexed by the location timestamps
                        setLocations(() => {
                            const locationsMap = new Map<number, MapLocationType>();
                            
                            state.data?.timeStampsMillis.forEach((value, index) => {
                                locationsMap.set(value, {
                                    lat: state.data?.locations[index].lat??null,
                                    lng: state.data?.locations[index].lng??null,
                                })
                            });
    
                            return locationsMap;
                        });
                    }
                    break;
                case "error":
                    setLocations(new Map<number, MapLocationType>());
                    break;
                case "idle":
                    setLocations(new Map<number, MapLocationType>());
                    break;
        }
    }, [state.status, state.data])

    const value = {locations, routes, isLoading : state.status === "in_progress"};

    return (
        <MapLocationsContext.Provider value={value}>
            {children}
        </MapLocationsContext.Provider>
    );
}

export { MapLocationsProvider };