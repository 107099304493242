﻿import styles from "./PanelTitle.module.css";

interface PanelTitleProps {
    title: string;
    uom?: string;
}
export const PanelTitle = ({ title, uom }: PanelTitleProps) => {

    return (
        <div>
            <div className={styles.title}>
                <span>{title}</span><span className={styles.uom}>{uom}</span>
            </div>
        </div>
    );
};
