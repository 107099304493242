// very much based on https://www.smashingmagazine.com/2020/07/custom-react-hook-fetch-cache-data/
// see https://www.carlrippon.com/cancelling-fetch-in-React-and-typescript/ for Cancelling fetch requests

import React from "react";
import { logger } from "../../debug/consoleLogger";

export interface IGetDataError{
    name: string,
}

export type GetDataStatus = "idle" | "in_progress" | "completed" | "error";

enum GetDataActionEnum {
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED_OK = "COMPLETED_OK",
    COMPLETED_ERROR = "COMPLETED_ERROR"
}

export interface IGetDataState<T> {
    status: GetDataStatus,
    error?: string,
    data?: T | undefined,
}

interface IGetDataAction<T> {
    type: GetDataActionEnum,
    payload?: T | string | undefined,
}

/**
 * Hook for retrieving data from an API.
 * @param url URL of the GET method to call
 * @param mapper A function that maps the data returned from the API to another model that is returned to the caller
 * @param requestId Optional value which will force a fetch even if the URL has not changed.
 * @returns 
 */
export function useGetData<T, U>(url: string, mapper: (data: U) => T, requestId?: number | undefined) {

    const initialState: IGetDataState<T> = {
        status: "idle",
        error: undefined,
        data: undefined,
    }

    const [state, dispatch] = React.useReducer((state: IGetDataState<T>, action: IGetDataAction<T>) => {
        const {type, payload} = action;
        switch (type){
            case GetDataActionEnum.IN_PROGRESS:
                return {...state, status: "in_progress"} as IGetDataState<T>;
            case GetDataActionEnum.COMPLETED_OK:
                return {...state, status: "completed", data: payload} as IGetDataState<T>;
            case GetDataActionEnum.COMPLETED_ERROR:
                return {...state, status: "error", error: payload} as IGetDataState<T>;
            default:
                return state;
        }
    }, initialState);

    const ref = React.useRef<AbortController>();

    React.useEffect(() => {

        let cleanUp = false;

        if (!url) return;

        if (ref.current){
            ref.current.abort();
        }

        const fetchData = async () => {
            
            const controller = new AbortController();
            const signal = controller.signal;

                ref.current = controller;

            dispatch({
                type: GetDataActionEnum.IN_PROGRESS
            });

            try{
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    signal: signal,
                });

                if (response.ok){
                    if (cleanUp) return;
                    const serverData = await response.json() as U;
                    dispatch({
                        type: GetDataActionEnum.COMPLETED_OK, 
                        payload: mapper(serverData)
                    });
                } else {
                    if (cleanUp) return;
                    dispatch({
                        type: GetDataActionEnum.COMPLETED_ERROR, 
                        payload: response.statusText
                    });
                }
            } catch (error) {
                if (cleanUp) return;
                if ((error as IGetDataError).name !== "AbortError") {
                    dispatch({
                        type: GetDataActionEnum.COMPLETED_ERROR, 
                        payload: (error as IGetDataError).name
                    });
                    logger.warn("error " + JSON.stringify(error));
                    throw error;
                }
            }
        }

        try {
            fetchData().catch((reason) => { logger.error(reason as string)});
        }
        catch (error){
            logger.error(error as string);
        }

        return function doCleanUp() {
            cleanUp = true;
            if (ref.current) {ref.current.abort()}
        }
 
    }, [url, requestId, mapper]);

    return state;
}