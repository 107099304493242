import { max as d3max } from "d3-array";
import {
    scaleLinear as d3scaleLinear,
    scaleUtc as d3scaleUtc
} from "d3-scale";
import { Fragment, useEffect, useRef, useState } from "react";
import {
    Area,
    BottomAxis,
    ChartContainer,
    ChartMargins,
    LeftAxis,
    RightAxis,
    VerticalBars,
} from "../chartComponents";
import { Panel } from "../panelComponents";
import { ChartKeys } from "./ChartKeys";
import { Summary } from "./Summary";
import { generateTicksForYAxis } from "../chartComponents/Utils";

export interface PerformanceDataModel {
    chartData: ChartData[];
    summaryData: SummaryData;
}

interface ChartData {
    timeStampMillis: number;
    upload: number;
    download: number;
    latency: number;
}

interface SummaryData {
    maxDownload: number;
    maxUpload: number;
    maxLatency: number;
    avgLatency: number;
    maxDropRate: number;
    avgDropRate: number;
    connectedState: ConnectedState[],
}

interface ConnectedState {
    stateName: string;
    statePercentage: number;
}

export const Performance = ({ data }: { data: PerformanceDataModel }) => {

    const containerRef: React.RefObject<HTMLDivElement> = useRef(null);
    const [chartWidth, setChartWidth] = useState(0);

    useEffect(() => {
        function handleResize() {
            if (containerRef.current) {
                setChartWidth(containerRef.current.clientWidth - 2);
            }
        }

        // watch for window resize events
        window.addEventListener("resize", handleResize);

        // get initial size
        handleResize();

        // remove event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [containerRef]);

    return (
        <div ref={containerRef}>
            <Container
                chartWidth={chartWidth}
                data={data}
            />
        </div>
    )
}

const Container = ({
    chartWidth,
    data,
}: {
        chartWidth: number,
        data: PerformanceDataModel,
    }) => {

    const chartHeight = 150;

    return (
        <Panel title="Performance Over Time">
            <ChartContainer overallHeight={chartHeight}>
                <Chart
                    chartWidth={chartWidth}
                    chartHeight={chartHeight}
                    data={data.chartData}
                />
            </ChartContainer>
            <>
                <ChartKeys />
                <Summary data={data.summaryData} />
            </>
        </Panel>
    )
}

const chartMargins: ChartMargins = {
    marginLeft: 50,
    marginRight: 80,
    xAxisHeight: 20,
}

const Chart = ({
    chartWidth,
    chartHeight,
    data
}: {
        chartWidth: number,
        chartHeight: number,
        data: ChartData[]
}) => {

    if (data.length === 0) return null;

    const minTimeStamp = data[0].timeStampMillis;
    const maxTimeStamp = data[data.length - 1].timeStampMillis;

    const maxUpload = d3max(data, d => d.upload) ?? 0;
    const maxDownload = d3max(data, d => d.download) ?? 0;
    const maxSpeed = d3max([maxUpload, maxDownload]) ?? 0;
    const maxSpeedAdjusted = maxSpeed > 0 ? maxSpeed : 1;
    const maxLatency = d3max(data, d => d.latency) ?? 0;
    const maxLatencyAdjusted = maxLatency > 0 ? maxLatency : 1;

    const contentWidth = chartWidth - chartMargins.marginLeft - chartMargins.marginRight;
    const contentHeight = chartHeight - chartMargins.xAxisHeight;

    // a time based scale for the x axis
    const xScale = d3scaleUtc()
        .domain([minTimeStamp, maxTimeStamp])
        .range([chartMargins.marginLeft + 20, chartWidth - chartMargins.marginRight - 20]);

    const speedDomain = [0, maxSpeedAdjusted];
    const latencyDomain = [0, maxLatencyAdjusted];
    //console.log("speed domain", speedDomain);
    //console.log("latency domain", latencyDomain);

    // a number scale for the left (speed) y axis
    const speedYScale = d3scaleLinear()
        .domain(speedDomain)
        .range([contentHeight, 10])
        .nice();

    // a number scale for the right (latency) y axis
    const latencyYScale = d3scaleLinear()
        .domain(latencyDomain)
        .range([contentHeight, 10])
        .nice();

    // generate ticks for the y scales
    const speedTicks = generateTicksForYAxis(speedYScale.domain());
    const latencyTicks = generateTicksForYAxis(latencyYScale.domain());

    return (
        <Fragment>
            <LeftAxis
                tickValues={speedTicks}
                scale={speedYScale}
                chartWidth={contentWidth}
                chartHeight={contentHeight}
                marginLeft={chartMargins.marginLeft}
                labelText="Speed (Mbps)"
            />
            <BottomAxis
                width={contentWidth}
                y={chartHeight - chartMargins.xAxisHeight}
                scale={xScale}
            />
            <RightAxis
                tickValues={latencyTicks}
                scale={latencyYScale}
                chartWidth={chartWidth}
                chartHeight={contentHeight}
                marginRight={chartMargins.marginRight}
                labelText="Latency (ms)"
            />
            <VerticalBars
                color='#007BFF'
                data={data.map((d) => ({
                    timeStampMillis: d.timeStampMillis,
                    value: d.latency
                }))}
                barWidth={4}
                height={contentHeight}
                xScale={xScale}
                yScale={latencyYScale}
            />
            <Area
                color='#28A745'
                data={data.map((d) => ({
                    timeStampMillis: d.timeStampMillis,
                    value: d.download
                }))}
                xScale={xScale}
                yScale={speedYScale}
            />
            <Area
                color='#FFC107'
                data={data.map((d) => ({
                    timeStampMillis: d.timeStampMillis,
                    value: d.upload
                }))}
                xScale={xScale}
                yScale={speedYScale}
            />

        </Fragment>
    )
}