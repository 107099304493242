import { useEffect, useState } from "react";
import { api, useGetData } from "../../components";
import { IEditScheduledChangesListModel } from "./Models";


/**
 * Shape of the data that is returned from the remote API call
 */
interface IApiModel {
    currentServerDateMillis: number,
    endOfTodayMillis: number,
    newChangeDefaultStartDateMillis: number,
    scheduledChanges: IApiScheduledChangeModel[],
    ratePlans: IApiRatePlanModel[],
}

// TODO: should we add a synthetic ID to this model created 
// in the mapping. We would need to create new id values when they 
// were added via the UI.

interface IApiScheduledChangeModel {
    dailyAdjustment: number;
    createdByEmail: string;
    endDateMillis: number;
    startDateMillis: number;
    equipmentBillingSchemeId: number;
    ratePlanId: number;
    ratePlanName: string;
}

interface IApiRatePlanModel {
    id: number;
    name: string;
    dailyAdjustment: number;
}

/**
 * Maps data returned from the remote API to a UI model
 */
function mapper(apiData: IApiModel) : IEditScheduledChangesListModel {
    return {
        ...apiData,
        scheduledChanges: apiData.scheduledChanges.map((change) => {
            return {
                createdByEmail: change.createdByEmail,
                endDateMillis: change.endDateMillis,
                ratePlanId: change.ratePlanId,
                ratePlanName: change.ratePlanName,
                startDateMillis: change.startDateMillis,
                dailyAdjustment: change.dailyAdjustment,
                equipmentBillingSchemeId: change.equipmentBillingSchemeId
            }
        }),
        ratePlans: apiData.ratePlans.map((change) => {
            return {
                dailyAdjustment: change.dailyAdjustment,
                id: change.id,
                name: change.name
            }
        })
    }
}

/**
 * Hook to retrieve data for use when editing the Package Changes
 * for a given Installation, SABA Equipment Id and WAN Interface Id
 */
export const useFetchEditScheduledChanges = (
    wanInterfaceId: number | undefined,
) => {
    
    const [url, setUrl] = useState<string>('');
    const state = useGetData(url, mapper);

    useEffect(() => {

        if (typeof wanInterfaceId === "undefined") return;

        const url = api.urls.packages.get.editScheduledChanges(wanInterfaceId);
        setUrl(url);
        
    }, [wanInterfaceId]);

    return { state };
}