import styles from "./ObstructionImage.module.css";

export const ObstructionImage = ({ data }: { data: string | undefined }) => {
    return (
        <div className={styles.root}>
            <img
                src={`data:image/png;base64, ${data}`}
                title="Obstruction Image"
                style={{ width: '200px' }}
            />
        </div>
    )
}