import styles from "./Uptime.module.css";

export const Uptime = ({ uptime }: { uptime: number | undefined }) => {

    const dhm = daysHoursMinutes(uptime ?? 0);
    const uptimeDays = dhm.split(':')[0];
    const uptimeHours = dhm.split(':')[1];
    const uptimeMinutes = dhm.split(':')[2];

    return (
        <div className={styles.root}>
            <div className={styles.item}>
                <div className={styles.value}>
                    {uptimeDays}
                </div>
                <div>
                    DAYS
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.value}>
                    {uptimeHours}
                </div>
                <div>
                    HOURS
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.value}>
                    {uptimeMinutes}
                </div>
                <div>
                    MINUTES
                </div>
            </div>
        </div>
    )
}

function daysHoursMinutes(t: number, multiplier: number = 1): string {
    const cd: number = 24 * 60 * 60 * multiplier;
    const ch: number = 60 * 60 * multiplier;
    let d = Math.floor(t / cd);
    let h = Math.floor((t - d * cd) / ch);
    let m = Math.round((t - d * cd - h * ch) / (60 * multiplier));
    const pad = function (n: number) { return n < 10 ? '0' + n : n; };

    if (m === 60) {
        h++;
        m = 0;
    }
    if (h === 24) {
        d++;
        h = 0;
    }
    return [d, pad(h), pad(m)].join(':');
}