/**
 * TODO: it may be better if we could find a way to have the Content
 * panel as a stand alone component and move the navigation into the ShipView Page
 * as the content is actually linked to the ship view page
 */
import React, { useCallback, useContext } from "react";

import classNames from "classnames";
import styles from "./Content.module.css";

import { AuthContext } from "../../../../contexts/components/AuthContext";
import { ContentType } from "./ContentType";
import {
    CloseButton,
    ContentContainer,
    DataPackages,
    InvoiceHistory,
    Navigation,
    NavigationButton,
    VesselConnectivity,
    Starlink,
} from "./components";


interface INavigationItem{
    id: ContentType,
    title: string,        
    component: React.ReactNode,
    icon: React.ReactNode,
}

function makeNavigationItems() : INavigationItem[]{

    const items : INavigationItem[] = 
    [
        {
            id: "vessel_connectivity",
            title: "Vessel Connectivity",
            component: <VesselConnectivity key={1}/>,
            icon: <ChartIcon title={"Vessel Connectivity"}/>
        }, 
        {
            id: "data_packages",
            title: "Data Packages",
            component: <DataPackages key={2}/>,
            icon: <SimCardIcon title="Data Packages" />
        }
        , 
        {
            id: "invoice_history",
            title: "Invoice History",
            component: <InvoiceHistory key={3}/>,
            icon: <DollarsIcon title="Invoice History"/>
        },
        {
            id: "starlink",
            title: "Starlink",
            component: <Starlink key={4} />,
            icon: <StarlinkIcon title="Starlink" />
        }
    ]
    
    return items;
}

export interface LoadingStatus {
    isLoading: boolean,
    showStarlink: boolean,
}

const Content = ({ loadingStatus }: {loadingStatus: LoadingStatus}) => {

    const {user} = useContext(AuthContext);

    const [selectedComponentId, setSelectedComponentId] = React.useState<ContentType | undefined>(undefined);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    function handleOnClick(id: ContentType){
        setSelectedComponentId(id);
        setIsOpen(true);
    }

    function handleOnCloseContent(){
        setIsOpen(false);
        // NOTE : the following had been removed in previous versions
        // because of some display ugliness, but it no longer seems to be the case
        setSelectedComponentId(undefined);
    }

    // get the navigation items that are valid for the current user
    const navigationItems = useCallback(() => {
        return makeNavigationItems()
            .filter(value => value.id === "invoice_history" ? user?.canViewInvoiceHistory : true)
            .filter(value => value.id === "starlink" ? loadingStatus.showStarlink : true)
    },[user, loadingStatus.showStarlink]);

    if (loadingStatus.isLoading) return null;
    
    return (
        <div className={`${classNames(styles.root, isOpen && styles.open)}`}>
            <Navigation>
                {navigationItems().map((value) => {
                    return (
                        <NavigationButton 
                            key={`navigation-button-${value.id}`}
                            id={value.id} 
                            icon={value.icon} 
                            isCurrent={value.id === selectedComponentId} 
                            onClick={handleOnClick} 
                        />
                    )
                })}
            </Navigation>
            <ContentContainer>
            {navigationItems().map((value) => {
                return (value.id === selectedComponentId ? value.component : null);
            })}
            </ContentContainer>
            <div className={styles.buttonContainer}>
                {isOpen &&
                    <CloseButton onClick={handleOnCloseContent}/>
                }
            </div>
        </div>
    );
}

const ChartIcon = (props: { title: string }) => {

    const { title } = props;

    return (
        <svg viewBox="0 0 28 28" >
            <title>{title }</title>
            <g fillRule="evenodd">
                <g transform="translate(0.000000, 3.000000)" fillRule="nonzero">
                    <path d="M1.504,18 C0.677,18 0.004,17.327 0.004,16.5 L0.004,1.5 C0.004,0.673 0.677,0 1.504,0 L22.504,0 C23.331,0 24.004,0.673 24.004,1.5 L24.004,16.5 C24.004,17.327 23.331,18 22.504,18 L1.504,18 Z M1.504,1 C1.22785763,1 1.004,1.22385763 1.004,1.5 L1.004,16.5 C1.004,16.7761424 1.22785763,17 1.504,17 L22.504,17 C22.7801424,17 23.004,16.7761424 23.004,16.5 L23.004,1.5 C23.004,1.22385763 22.7801424,1 22.504,1 L1.504,1 Z" id="Shape"></path>
                    <path d="M4.5,13.0000135 C4.31935071,13.0013269 4.15214941,12.9047183 4.0630794,12.7475481 C3.97400939,12.590378 3.97704045,12.3972969 4.071,12.243 L6.642,7.958 C6.82170986,7.6549497 7.14867777,7.4699597 7.501,7.472 C7.85209427,7.47163054 8.17739509,7.65632236 8.357,7.958 L10.57,11.643 L12.235,9.643 C12.4256603,9.41686769 12.7062539,9.28620596 13.0020359,9.28582058 C13.297818,9.28543519 13.5787511,9.41536529 13.77,9.641 L15.427,11.629 L19.066,5.253 C19.1522731,5.09336734 19.3182594,4.99300425 19.4996998,4.99072664 C19.6811402,4.98852553 19.849553,5.08476142 19.9397396,5.24221619 C20.0299262,5.39967097 20.0277295,5.59362817 19.934,5.749 L16.295,12.126 C16.1177472,12.4363154 15.7883678,12.6284533 15.431,12.63 C15.1324264,12.6318019 14.8487617,12.4996942 14.658,12.27 L13.003,10.284 L11.339,12.284 C11.0927676,12.5788507 10.6998778,12.7073552 10.327,12.615 C10.0694823,12.5509056 9.84814297,12.3867906 9.712,12.159 L7.5,8.472 L4.928,12.757 C4.83783668,12.9074699 4.67541528,12.9996858 4.5,13.0000135 Z" id="Path"></path>
                </g>
            </g>
        </svg>
    );
}

const DollarsIcon = (props: { title: string }) => {

    const { title } = props;

    return (
        <svg viewBox="0 0 28 28">
            <title>{title}</title>
            <g fillRule="evenodd">
                <g transform="translate(0.000000, 3.000000)" fillRule="nonzero">
                    <path d="M1.504,13.5 C0.677,13.5 0.004,12.827 0.004,12 L0.004,2 C0.004,1.173 0.677,0.5 1.504,0.5 L18.504,0.5 C19.331,0.5 20.004,1.173 20.004,2 L20.004,12 C20.004,12.827 19.331,13.5 18.504,13.5 L1.504,13.5 Z M1.504,1.5 C1.22785763,1.5 1.004,1.72385763 1.004,2 L1.004,12 C1.004,12.2761424 1.22785763,12.5 1.504,12.5 L18.504,12.5 C18.7801424,12.5 19.004,12.2761424 19.004,12 L19.004,2 C19.004,1.72385763 18.7801424,1.5 18.504,1.5 L1.504,1.5 Z" id="Shape"></path>
                    <path d="M2.5,15.5 C2.22385763,15.5 2,15.2761424 2,15 C2,14.7238576 2.22385763,14.5 2.5,14.5 L20.5,14.5 C20.7761424,14.5 21,14.2761424 21,14 L21,3 C21,2.72385763 21.2238576,2.5 21.5,2.5 C21.7761424,2.5 22,2.72385763 22,3 L22,14 C22,14.827 21.327,15.5 20.5,15.5 L2.5,15.5 Z" id="Path"></path>
                    <path d="M4.5,17.5 C4.22385763,17.5 4,17.2761424 4,17 C4,16.7238576 4.22385763,16.5 4.5,16.5 L22.5,16.5 C22.7761424,16.5 23,16.2761424 23,16 L23,5 C23,4.72385763 23.2238576,4.5 23.5,4.5 C23.7761424,4.5 24,4.72385763 24,5 L24,16 C24,16.827 23.327,17.5 22.5,17.5 L4.5,17.5 Z" id="Path"></path>
                    <path d="M10.004,10.5 C8.074,10.5 6.504,8.93 6.504,7 C6.504,5.07 8.074,3.5 10.004,3.5 C11.934,3.5 13.504,5.07 13.504,7 C13.504,8.93 11.934,10.5 10.004,10.5 Z M10.004,4.5 C8.625,4.5 7.504,5.621 7.504,7 C7.504,8.379 8.625,9.5 10.004,9.5 C11.383,9.5 12.504,8.379 12.504,7 C12.504,5.621 11.382,4.5 10.004,4.5 Z" id="Shape"></path>
                    <path d="M2.504,11.5 C2.22785763,11.5 2.004,11.2761424 2.004,11 C2.004,10.7238576 2.22785763,10.5 2.504,10.5 L4.504,10.5 C4.78014237,10.5 5.004,10.7238576 5.004,11 C5.004,11.2761424 4.78014237,11.5 4.504,11.5 L2.504,11.5 Z" id="Path"></path>
                    <path d="M15.504,3.5 C15.2278576,3.5 15.004,3.27614237 15.004,3 C15.004,2.72385763 15.2278576,2.5 15.504,2.5 L17.504,2.5 C17.7801424,2.5 18.004,2.72385763 18.004,3 C18.004,3.27614237 17.7801424,3.5 17.504,3.5 L15.504,3.5 L15.504,3.5 Z" id="Path"></path>
                </g>
            </g>
        </svg>
    );
}

const SimCardIcon = (props: { title: string }) => {

    const { title } = props;

    return (
        <svg viewBox="0 0 28 28">
            <title>{title}</title>
            <g>
                <path d="M4.5 24c-.827 0-1.5-.673-1.5-1.5v-21C3 .673 3.673 0 4.5 0h10.527c.45 0 .873.199 1.159.547l4.472 5.442c.221.266.342.605.342.953V22.5c0 .827-.673 1.5-1.5 1.5h-15zm0-23a.5.5 0 0 0-.5.5v21a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5V6.941a.494.494 0 0 0-.113-.315l-4.473-5.444A.502.502 0 0 0 15.028 1H13v3.5a.5.5 0 0 1-1 0V1h-2v3.5a.5.5 0 0 1-1 0V1H7v3.5a.5.5 0 0 1-1 0V1H4.5z" /><path d="M8.5 21A2.503 2.503 0 0 1 6 18.5v-5C6 12.121 7.121 11 8.5 11h7c1.378 0 2.5 1.121 2.5 2.5v5c0 1.378-1.122 2.5-2.5 2.5h-7zm7-1c.827 0 1.5-.673 1.5-1.5V18h-2v2h.5zM14 20v-8h-4v8h4zm-7-1.5c0 .827.673 1.5 1.5 1.5H9v-2H7v.5zM17 17v-2h-2v2h2zm-8 0v-2H7v2h2zm8-3v-.5c0-.827-.673-1.5-1.5-1.5H15v2h2zm-8 0v-2h-.5c-.827 0-1.5.673-1.5 1.5v.5h2z" />
            </g>
        </svg>
    );
}

const StarlinkIcon = (props: { title: string }) => {
    const { title } = props;

    return (
        <svg viewBox="0 0 28 28">
            <title>{title}</title>
            <g>
                <circle cx="13" cy="13" r="12" />
                <circle cx="13" cy="15" r="10" />
                <circle cx="13" cy="13" r="8" />
                <circle cx="13" cy="5" r="1" strokeWidth={2} />
                <circle cx="13" cy="13" r="1" strokeWidth={2} />
                <circle cx="13" cy="25" r="1" strokeWidth={2} />
            </g>
        </svg>
    )
}

export default Content;