import { useContext } from "react";
import { AuthContext } from "../../../../../../../../../contexts/components/AuthContext";
import { SelectedShipContext } from "../../../../../../../../../contexts/components/SelectedShipContext";
import { useFetchPackageItems } from "../../../../../../../../../dataServices/dataPackages/packageItems";
import { PackageItem } from "./PackageItem";
import styles from "./PackageItemsList.module.css";

export const PackageItemsList : React.FC = () => {

    // respond to changes in the selected vessel
    const {ship} = useContext(SelectedShipContext);

    // get the package items for the selected vessel
    const state = useFetchPackageItems(ship?.installationId);

    // need to get the user details to determine if pricing should be hidden
    const {user} = useContext(AuthContext);

    // prevent prices from being shown if the user is not allowed to view package prices
    const hidePricing = user !== null ? !user.canViewPackagePrices : true;

    return (
        <div className={styles.root}>
            {state.status === "in_progress" && <div>Loading...</div>}
            {state.status === "error" && <div>Ooops.  Sorry, something went wrong...</div>}
            {(state.status === "completed" || state.status === "idle") && state.data && 
                (
                    state.data.length > 0
                    ? state.data.map((value, index) => {
                            return (
                                <PackageItem 
                                    key={index} 
                                    packageItem={value}
                                    hidePricing={hidePricing}
                                />
                            )
                        })
                        : <div>Nothing found</div>
                )
            }
        </div>
    )
}
