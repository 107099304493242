import { ErrorBoundary } from "react-error-boundary";
import {
    MapDatePointProvider,
    MapDateRangeProvider,
    MapLocationsProvider,
    SelectedShipProvider,
} from "../../../contexts";
import styles from "./ShipViewPage.module.css";
import { Content, LoadingStatus } from "./content";
import { Installations } from "./installations";
import { MapAndControls } from "./map";
import { useState } from "react";


const ShipViewPage = () =>{

    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>({isLoading: true, showStarlink: false});

    const handleInstallationsLoaded = (starlinkCount: number) => {
        setLoadingStatus({
            isLoading: false,
            showStarlink: starlinkCount > 0
        });
    }

    return (
        <SelectedShipProvider>
            <MapDatePointProvider>
                <MapDateRangeProvider>
                    <MapLocationsProvider>
                        <ErrorBoundary fallback={<div>Something went wrong</div>}>
                            <main className={styles.root}>          
                                <Installations hasLoaded={handleInstallationsLoaded} />
                                <MapAndControls />
                                <Content loadingStatus={loadingStatus} />
                            </main>
                        </ErrorBoundary>
                    </MapLocationsProvider>
                </MapDateRangeProvider>
            </MapDatePointProvider>
        </SelectedShipProvider>
    );
}

export default ShipViewPage;