import { useContext, useEffect, useState } from "react";
import { MapDateRangeContext } from "../../../../../../contexts/components/MapDateRangeContext";
import { SelectedShipContext } from "../../../../../../contexts/components/SelectedShipContext";
import { useFetchStarlinkData } from "../../../../../../dataServices/starlink";
import { StarlinkDataModel } from "../../../../../../dataServices/starlink/Models";
import { ContentHeader } from "../contentContainer/ContentHeader";
import { NothingSelectedMessage } from "../contentContainer/NothingSelectedMsg";

import {
    IDataState,
    stateIsError,
    stateIsIdle,
    stateIsLoading,
} from "./dataState";

import { DataVolumes, DataVolumesDataModel } from "./dataVolumes";
import { Performance, PerformanceDataModel } from "./performance";
import { Status, StatusDataModel } from "./status";
import { max as d3max, mean as d3mean } from "d3-array";

import styles from "./Starlink.module.css";

export const Starlink = () => {

    const { ship } = useContext(SelectedShipContext);

    // the enclosing fragment is important to leave as a fragment
    // because it allows the content to scroll
    return (
        <>
            <ContentHeader title="Starlink" />
            {
                ship
                    ? <Content installationId={ship.installationId} />
                    : <NothingSelectedMessage />
            }
        </>
    )
}

const Content = ({ installationId }: {installationId: number}) => {

    const { fromMillis, toMillis } = useContext(MapDateRangeContext);
    const [state, setState] = useState<IDataState<null | StarlinkDataModel>>(stateIsIdle);
    const {state: fetchData} = useFetchStarlinkData(installationId, fromMillis, toMillis);

    useEffect(() => {
        switch (fetchData.status) {
            case "in_progress":
                setState(stateIsLoading);
                break;
            case "completed":
                setState({
                    isLoading: false,
                    isError: false,
                    data: fetchData.data
                        ? fetchData.data
                        : null
                });
                //console.log("starlink data", fetchData.data);
                break;
            case "error":
                setState(stateIsError);
                break;
            default:
                setState(stateIsIdle);
        }
    }, [fetchData.status, fetchData.data]);

    if (state.isLoading) return <div>Loading...</div>

    if (state.isError) return <div>Ooops. Something went wrong</div>

    if (state.data && !state.data.hasStarlink) return <div>Starlink Not Present</div>

    if (state.data && state.data.hasStarlink) {

        // Build the values to show on the Performance Chart.
        // Map the separate arrays provided by the API
        // to arrays of objects
        const performanceData: PerformanceDataModel = {
            chartData: state.data.performance.timeStampsMillis.map((d, i) => ({
                timeStampMillis: d,
                download: state.data ? state.data.performance.maxDownlinkRateMbps[i] : 0,
                upload: state.data ? state.data.performance.maxUplinkRateMbps[i] : 0,
                latency: state.data ? state.data.performance.maxPopPingLatency[i] : 0,
            })),
            summaryData: {
                maxDownload: state.data ? d3max(state.data.performance.maxDownlinkRateMbps) ?? 0 : 0,
                maxUpload: state.data ? d3max(state.data.performance.maxUplinkRateMbps) ?? 0 : 0,
                maxLatency: state.data ? d3max(state.data.performance.maxPopPingLatency) ?? 0 : 0,
                maxDropRate: state.data ? d3max(state.data.performance.maxPopPingDropRate) ?? 0 : 0,
                avgDropRate: state.data ? d3mean(state.data.performance.averagePopPingDropRate) ?? 0 : 0,
                avgLatency: state.data ? d3mean(state.data.performance.averagePopPingLatency) ?? 0 : 0,
                connectedState: state.data.performance.stateName.map((d, i) => ({
                    stateName: d,
                    statePercentage: state.data ? state.data.performance.statePercentage[i] : 0
                }))
            }
        }

        // Build the values to show on the Data Volumes Chart.
        // Map the two separate arrays provided by the API
        // to an array of objects
        const dataVolumesData: DataVolumesDataModel = {
            chartData: state.data.dataVolumes.timeStampMillis.map((d, i) => ({
                timeStampMillis: d,
                value: state.data ? state.data.dataVolumes.values[i] : 0
            })),
        }

        // build the values to show in the Status component
        const statusData: StatusDataModel = {
            info: { ...state.data.status.info },
            alerts: { ...state.data.status.alerts }
        };

        return (
            <div className={styles.root}>
                <DataVolumes data={dataVolumesData} />
                <Performance data={performanceData} />
                <Status data={statusData} />
                {/*<pre style={{maxWidth: '400px'} }>*/}
                {/*    {JSON.stringify(state.data,null,2)}*/}
                {/*</pre>*/}
            </div>
        )
    }
}