import { createRef, RefObject, useEffect } from "react";
import { AxisScale as d3AxisScale } from "d3-axis";
import { select as d3select } from "d3-selection";
import { axisBottom as d3axisBottom } from "d3-axis";

interface BottomAxisProps {
    width: number;
    y: number;
    scale: d3AxisScale<Date | number>;
}

export const BottomAxis = ({width, y, scale } : BottomAxisProps) => {

    const elRef: RefObject<SVGGElement> = createRef();

    useEffect(() => {
        if (elRef.current) {
            d3select(elRef.current)
                .call(d3axisBottom(scale)
                    .tickSizeOuter(0)
            )
                .call((g => g.select(".domain").remove()));
        }
    }, [width, elRef, scale])

    return (
        <g ref={elRef} transform={`translate(0, ${y})`}></g>
    )
}