import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Dates } from "../../../../../../../utils/dates";
import { froundToIntlString } from "../../../../../../../utils/rounding";
import { Grid, GridCol } from "./Grid";
import { ObstructionImage } from "./ObstructionImage";
import { Table, TableRow } from "./Table";
import { Title } from "./Title";
import { Uptime } from "./Uptime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Status.module.css";

export interface StatusDataModel {
    info: {
        timeMillis: number;
        obstructionImageData: string;
        currentlyObstructed: boolean;
        obstructionDuration: number;
        obstructionInterval: number;
        fractionObstructed: number;
        azimuth: number;
        elevation: number;
        snrAboveNoise: boolean;
        popPingDropRate: number;
        popPingLatency: number;
        downlink: number;
        uplink: number;
        hardwareVersion: string;
        softwareVersion: string;
        state: string;
        alerts: number;
        uptime: number;
    },
    alerts: {
        motorsStuck: boolean;
        thermalThrottle: boolean;
        thermalShutdown: boolean;
        mastNotNearVertical: boolean;
        unexpectedLocation: boolean;
        slowEthernetSpeeds: boolean;
        slowEthernetSpeeds100: boolean;
        roaming: boolean;
        installPending: boolean;
        isHeating: boolean;
        powerSupplyThermalThrottle: boolean;
        isPowerSaveIdle: boolean;
        movingWhileNotMobile: boolean;
        movingTooFastForPolicy: boolean;
        dbfTelemStale: boolean;
        lowMotorCurrent: boolean;
        lowerSignalThanPredicted: boolean;
        obstructionMapReset: boolean;
    }
}

export const Status = ({ data }: { data: StatusDataModel }) => {

    return (
        <div className={styles.root}>
            <Grid>
                <GridCol>
                    <Title text="Obstruction" />
                    <ObstructionImage data={data.info.obstructionImageData} />

                    <Title text="Obstruction Info" />
                    <Table>
                        <TableRow
                            label="Currently Obstructed:"
                            value={data.info.currentlyObstructed ? 'True' : 'False'}
                        />
                        <TableRow
                            label="Obstruction Duration:"
                            value={`${froundToIntlString(data.info.obstructionDuration ?? 0, 2)} secs`}
                        />
                        <TableRow
                            label="Obstruction Interval:"
                            value={`${data.info.obstructionInterval} secs`}
                        />
                        <TableRow
                            label="Fraction Obstructed:"
                            value={`${froundToIntlString(data.info.fractionObstructed ?? 0, 2)}%`}
                        />
                        <TableRow
                            label="Azimuth:"
                            value={froundToIntlString(data.info.azimuth ?? 0, 4)}
                        />
                        <TableRow
                            label="Elevation:"
                            value={froundToIntlString(data.info.elevation ?? 0, 4)}
                        />
                        <TableRow
                            label="SNR Above Noise:"
                            value={data.info.snrAboveNoise ? 'True' : 'False'}
                        />
                    </Table>
                </GridCol>

                <GridCol>
                    <Title text="Alerts" />
                    <Table>
                        <TableRow
                            label="Motors Stuck"
                            value={data.alerts.motorsStuck ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Thermal Throttle"
                            value={data.alerts.thermalThrottle ? XMark : CheckMark }
                        />
                        <TableRow
                            label="Thermal Shutdown"
                            value={data.alerts.thermalShutdown ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Mast Not Near Vertical"
                            value={data.alerts.mastNotNearVertical ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Unexpected Location"
                            value={data.alerts.unexpectedLocation ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Slow Ethernet Speeds"
                            value={data.alerts.slowEthernetSpeeds ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Roaming"
                            value={data.alerts.roaming ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Install Pending"
                            value={data.alerts.installPending ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Is Heating"
                            value={data.alerts.isHeating ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Power Supply Thermal Throttle"
                            value={data.alerts.powerSupplyThermalThrottle ? XMark : CheckMark}
                        />  
                        <TableRow
                            label="Is Power Save Idle"
                            value={data.alerts.isPowerSaveIdle ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Moving While Not Mobile"
                            value={data.alerts.movingWhileNotMobile ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Moving Too Fast For Policy"
                            value={data.alerts.movingTooFastForPolicy ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Dbf Telem Stale"
                            value={data.alerts.dbfTelemStale ? XMark : CheckMark}
                        />
                        <TableRow
                            label="Low Motor Current"
                            value={data.alerts.lowMotorCurrent ? XMark : CheckMark}
                        />
                    </Table>
                </GridCol>

                <GridCol>
                    <Title text="Latest" />
                    <Table>
                        <TableRow
                            label="Date:"
                            value={Dates.fromMillis(data.info.timeMillis ?? 0).toString(Dates.DATE_FORMAT_SHORT_WITHOUT_SECONDS)}
                        />
                        <TableRow
                            label="POP Ping Drop Rate:"
                            value={`${froundToIntlString(data.info.popPingDropRate ?? 0, 2)}%`}
                        />
                        <TableRow
                            label="POP Ping Latency:"
                            value={`${froundToIntlString(data.info.popPingLatency ?? 0, 0)}ms`}
                        />
                        <TableRow
                            label="Downlink:"
                            value={`${froundToIntlString((data.info.downlink ?? 0) / 1000000, 2)}Mbps`}
                        />
                        <TableRow
                            label="Uplink:"
                            value={`${froundToIntlString((data.info.uplink ?? 0) / 1000000, 2)}Mbps`}
                        />
                    </Table>

                    <Title text="Current Info" />
                    <Table>
                        <TableRow
                            label="Hardware Version:"
                            value={data.info.hardwareVersion}
                        />
                        <TableRow
                            label="Software Version:"
                            value={data.info.softwareVersion}
                        />
                        <TableRow
                            label="State:"
                            value={data.info.state}
                        />
                        <TableRow
                            label="Alerts:"
                            value={data.info.alerts}
                        />
                    </Table>

                    <Title text="Uptime" />
                    <Uptime uptime={data.info.uptime } />
                </GridCol>
            </Grid>
        </div>
    )
}

const CheckMark = () => {
    return (
        < FontAwesomeIcon icon={faCheck} className={styles.checkMark} />
    );
}

const XMark = () => {
    return (
        <FontAwesomeIcon icon={faXmark} className={styles.xMark} />
    )
}