import { RefObject, createRef, useEffect } from "react";
import { select as d3select } from "d3-selection";
import { axisRight as d3axisRight, AxisScale as d3AxisScale } from "d3-axis";

interface RightAxisProps {
    scale: d3AxisScale<number>;
    tickValues: number[];
    chartWidth: number;
    chartHeight: number;
    marginRight: number;
    labelText: string;
}

export const RightAxis = ({
    scale,
    tickValues,
    chartWidth,
    chartHeight,
    marginRight,
    labelText,
}: RightAxisProps) => {

    const elRef: RefObject<SVGGElement> = createRef();

    useEffect(() => {
        if (elRef.current) {
            // this seems a bit heavy handed, but it works and I can't
            // find a way at the moment to only update various components
            // of the axis without them being duplicated

            // So.. remove all the components of the axis
            d3select(elRef.current).selectAll("path,line,text,g").remove();

            // before recreating the axis
            const axis = d3axisRight(scale);

            // adjust the ticks
            axis.tickValues(tickValues);

            d3select(elRef.current)
                // draw the axis
                .call(axis)
                // shorten the default tick mark lines
                .call((g =>
                    g.selectAll(".tick line")
                        .attr("x2", 0)
                ))
                // remove the axis base line
                .call((g =>
                    g.select(".domain")
                        .remove()
                ))
                .call((g =>
                    g.append("text")
                        .attr("transform", "rotate(+90)")
                        .attr("y", -35)
                        .attr("x", (chartHeight / 2))
                        .attr("fill", "currentColor")
                        .attr("text-anchor", "middle")
                        .text(labelText)
                ))
                ;
        }
    }, [elRef, scale, tickValues, chartHeight, labelText])

    return (
        <g ref={elRef} transform={`translate(${chartWidth - marginRight}, 0)`}></g>
    )
}