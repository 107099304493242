import { ScaleTime, ScaleLinear } from "d3-scale"; 
import { area as d3area} from "d3-shape";

interface DataItem {
    timeStampMillis: number;
    value: number;
}
interface AreaProps {
    xScale: ScaleTime<number, number, never>;
    yScale: ScaleLinear<number, number, never>;
    color: string;
    data: DataItem[];
}

export const Area = ({ xScale, yScale, color, data }: AreaProps) => {

    const areaBuilder = d3area<DataItem>()
        .x((d) => xScale(d.timeStampMillis))
        .y1((d) => yScale(d.value))
        .y0(yScale(0));

    const areaPath = areaBuilder(data)

    if (!areaPath) {
        return null;
    }

    return (
        <path
            d={areaPath}
            opacity={1}
            stroke="none"
            fill={color}
            fillOpacity={0.4}            
        />
    )
}