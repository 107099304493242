import { ScaleTime, ScaleLinear } from "d3-scale"; 

interface VerticalBarsProps {
    height: number;
    barWidth: number;
    xScale: ScaleTime<number, number, never>;
    yScale: ScaleLinear<number, number, never>;
    color: string;
    data: {
        timeStampMillis: number,
        value: number,
    }[]
}

export const VerticalBars = ({height, barWidth, xScale, yScale, color, data } : VerticalBarsProps) => {

    const bars = data.map(d => {

        const barProps: BarProps = {
            id: d.timeStampMillis,
            color: color,
            height: height - yScale(d.value),
            width: barWidth, 
            x: xScale(d.timeStampMillis) -  barWidth/2.5,
            y: yScale(d.value)
        };

        return <Bar key={barProps.id} {...barProps } />
    })

    return <g>{bars}</g>
}
interface BarProps {
    id: number;
    x: number;
    y: number;
    width: number;
    height: number;
    color: string;
}

const Bar = ({ id, x, y, width, height, color} : BarProps) => {
    return (
        <rect
            key={id}
            x={x}
            y={y}
            width={width < 0 ? 0 : width}
            height={height}
            fill={color}
            stroke="white"
            strokeWidth={1}           
        />
    )
}