import { range as d3range } from "d3-array";
export function generateTicksForYAxis(yDomain: number[]) {
    if (yDomain.length && yDomain.length === 2) {
        const min = yDomain[0];
        const max = yDomain[1];
        const step = (max - min) / 4;
        //console.log("yDomain", yDomain, "min", min, "max", max, "step:", step);
        const tickArray = d3range(min, max + step, step);
        //console.log("Range", tickArray);
        return tickArray;
    }
    else {
        throw Error("Invalid yDomain");
    }
}