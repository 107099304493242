export interface IDataState<T> {
    isLoading: boolean;
    isError: boolean;
    data: T;
}

export const stateIsLoading: IDataState<null> = {
    isLoading: true,
    isError: false,
    data: null,
}

export const stateIsIdle: IDataState<null> = {
    isLoading: false,
    isError: false,
    data: null,
}

export const stateIsError: IDataState<null> = {
    isLoading: false,
    isError: true,
    data: null,
}