import styles from "./ChartContainer.module.css";

interface ChartContainerProps {
    overallHeight: number;
    children: React.ReactNode;
}

export const ChartContainer = ({ overallHeight, children }: ChartContainerProps) => {
    return (
        <svg height={overallHeight} className={styles.root}>
            {children}
        </svg>
    )
}